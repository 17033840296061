<template>
    <section class="content">
        <!-- <div class="lang-switch-frame">
            <a
                v-for="(value, name) in config.languages"
                :key="name"
                :class="{ active: currentLanguage == name }"
                @click="langClick(name)"
            >
                <span :class="'fi fi-' + getFixedName(name)"></span>
            </a>
        </div> -->

        <div class="nav-item dropdown">
            <a
                class="nav-link dropdown-toggle btn btn-outline-secondary language-select"
                role="button"
                id="dropdown09"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <span
                    :class="'pr-4 fi fi-' + getFixedName(currentLanguage)"
                ></span>
                <span class="lang-text">
                    {{ getFixedText(currentLanguage) }}</span
                >
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdown09">
                <a
                    v-for="(value, name) in config.languages"
                    :key="name"
                    :class="{
                        'dropdown-item': true,
                        active: currentLanguage == name,
                    }"
                    @click="langClick(name)"
                >
                    <span :class="'pr-2 fi fi-' + getFixedName(name)"></span>
                    <span class="lang-text"> {{ getFixedText(name) }} </span>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "LanguageSwitcher",
    props: ["currentLanguage", "config"],
    data() {
        return {};
    },

    mounted: () => {},
    methods: {
        getFixedName(lang) {
            return lang == "en" ? "us" : lang;
        },
        getFixedText(lang) {
            return this.config.languages[lang];
        },
        langClick(pLang) {
            window.location.href = this.config.route + "/" + pLang;
        },
    },
};
</script>

<style scoped>
.language-select {
    height: 40px;
}
a {
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
}
.lang-text {
    padding-left: 6px;
}
</style>
