<template>
    <div class="card p-0 shadow mt-3" v-if="ready">
        <div class="card-body pt-0">
            <span class="grid-title">{{ $trans("strings.Transports") }}</span>

            <DxDataGrid
                ref="grid"
                :data-source="store"
                :remote-operations="true"
                :allow-column-resizing="true"
                :column-min-width="50"
                :column-auto-width="true"
                :row-alternation-enabled="true"
                :show-borders="true"
                :columnHidingEnabled="true"
            >
                <DxRemoteOperations
                    :filtering="true"
                    :paging="true"
                    :sorting="true"
                    :summary="true"
                    :grouping="true"
                    :group-paging="true"
                />
                <DxColumn
                    data-field="id"
                    data-type="number"
                    width="70px"
                    :visible="false"
                />
                <DxColumn type="buttons" :width="50">
                    <DxButton
                        :hint="$trans('strings.Show Route in Google Maps')"
                        icon="map"
                        :visible="true"
                        :disabled="false"
                        :on-click="showRoute"
                    />
                </DxColumn>
                <DxColumn
                    type="buttons"
                    name="b2"
                    :width="50"
                    :visible="showCart"
                >
                    <DxButton
                        :hint="$trans('strings.Order Transport')"
                        icon="cart"
                        :on-click="addCart"
                    />
                </DxColumn>
                <DxColumn
                    :caption="$trans('strings.Locations')"
                    data-field="locationnames"
                    data-type="string"
                    cell-template="locations-cell"
                />
                <template #locations-cell="{ data }">
                    <div style="font-weight: bold; font-size: 18px">
                        {{ data.text }}
                    </div>
                </template>
                <DxColumn
                    data-field="start_time"
                    data-type="datetime"
                    format="yyyy-MM-dd HH:mm:ss"
                    width="180px"
                    :caption="$trans('strings.Start_time')"
                />
                <DxColumn
                    data-field="end_time"
                    data-type="datetime"
                    format="yyyy-MM-dd HH:mm:ss"
                    width="180px"
                    :caption="$trans('strings.End_time')"
                />

                <DxPager
                    :allowed-page-sizes="pageSizes"
                    :show-info="true"
                    :show-navigation-buttons="true"
                    :show-page-size-selector="true"
                    :visible="true"
                />
                <DxPaging :page-size="10" />
                <DxSelection mode="single" />
            </DxDataGrid>
        </div>
    </div>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxEditing,
    DxSelection,
    DxExport,
    DxPopup,
    DxForm,
    DxButton,
    DxLookup,
    DxRemoteOperations,
    DxMasterDetail,
} from "devextreme-vue/data-grid";

import huMessages from "devextreme/localization/messages/hu.json";
import { locale, loadMessages } from "devextreme/localization";

import KVUtils from "../../common/KVUtils";

import CustomStore from "devextreme/data/custom_store";
import { nextTick } from "@vue/runtime-core";
//import DataSource from "devextreme/data/data_source";
let filterData = {};
let countChangedCB = null;

const store = new CustomStore({
    key: "id",
    load: (loadOptions) => {
        return axios
            .post("/common/get-enabled-shipments", {
                ...loadOptions,
                filterData,
            })
            .then((res) => {
                KVUtils.ajaxResultHandler(res.data);
                console.log(res.data);
                if (countChangedCB) {
                    countChangedCB(res.data.totalCount);
                }
                return res.data;
            })
            .catch((error) => {
                KVUtils.ajaxErrorHandler(error);
            })
            .finally(() => {});
    },
    insert: (values) => {},
    update: (key, values) => {},
    remove: (key) => {},
});

export default {
    name: "ShipmentsTable",

    components: {
        DxDataGrid,
        DxColumn,
        DxGrouping,
        DxGroupPanel,
        DxPager,
        DxPaging,
        DxSearchPanel,
        DxEditing,
        DxSelection,
        DxExport,
        DxPopup,
        DxForm,
        DxButton,
        DxRemoteOperations,
        DxMasterDetail,
    },

    props: {
        showCart: {
            default: true,
        },
    },

    data() {
        return {
            ready: false,
            store,
            pageSizes: [10, 25, 50, 100],
        };
    },
    methods: {
        showRoute(e) {
            this.$Progress.start();
            axios
                .post("/common/get-shipment-geo-list", {
                    shipment_id: e.row.data.id,
                })
                .then((data) => {
                    const url = KVUtils.makeGoogleMapRouteLink(data.data.data);
                    window.open(url, "_blank").focus();
                })
                .finally(() => this.$Progress.finish());
        },
        addCart(e) {
            this.$emit("shipment-add-cart", e);
        },
        search(pFilterData) {
            // store.load({
            //     skip: 0,
            //     take: 10,
            //     filterData: filterData,
            // });
            filterData = pFilterData;
            this.$refs.grid.instance.refresh();
        },
        ccCB(count) {
            this.$emit("total-count-changed", count);
        },
    },
    mounted() {},
    created() {
        countChangedCB = this.ccCB;

        nextTick(() => {
            const lang = document.getElementById("current-language").innerText;
            this.$lang.setLocale(lang);
            loadMessages(huMessages);
            locale(lang);
            this.ready = true;
        });
    },
};
</script>

<style scoped>
.dx-datagrid {
    background-color: transparent !important;
}

.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
    background-color: transparent !important;
}

.card {
    background-color: #e0e0e0a0;
    border-radius: 10px !important;
    font-size: 20px;
}
.card-body {
    margin-left: -15px;
    margin-right: -15px;
}
.card-title {
    margin: 5px 0px 5px -13px !important;
}
.grid-title {
    font-size: 16px;
    padding-bottom: 10px;
}
.column-icon {
    width: 24px !important;
    height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
}
</style>
