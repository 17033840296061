<template>
    <div
        v-if="ready"
        style="max-width: 1140px; margin: auto"
        class="shipment-search-container"
    >
        <div class="card p-0 shadow">
            <div class="card-body pt-0 pb-0">
                <h5 class="card-title p-0 m-0">
                    {{ $trans("strings.Transport Search") }}
                </h5>
                <div class="card-right-top">
                    <a
                        href="javascript:void(0)"
                        :onclick="() => (advancedMode = !advancedMode)"
                        >{{ $trans("strings.Advanced Search") }}</a
                    >
                </div>
                <div class="row p-0">
                    <div
                        class="input-group mb-3 col-md-6 col-lg-4 p-0"
                        style="width: 100%"
                    >
                        <LocationSelectBox
                            ref="wherefrom"
                            :value="startPlace.placeId"
                            :label="$trans('strings.Where From')"
                            @change-location="onWhereFromLocationChange"
                        />
                        <div v-if="advancedMode" style="width: 100%">
                            <DxNumberBox
                                :label="$trans('strings.Search Radius (From)')"
                                label-mode="floating"
                                v-model:value="startRadius"
                                format="#### km"
                            />
                        </div>
                    </div>
                    <div class="input-group mb-3 col-md-6 col-lg-4 pl-1 pr-1">
                        <LocationSelectBox
                            ref="whereto"
                            :value="endPlace.placeId"
                            :label="$trans('strings.Where To')"
                            @change-location="onWhereToLocationChange"
                        />
                        <div v-if="advancedMode" style="width: 100%">
                            <DxNumberBox
                                :label="$trans('strings.Search Radius (To)')"
                                label-mode="floating"
                                v-model:value="endRadius"
                                format="#### km"
                            />
                        </div>
                    </div>
                    <!-- width="auto" -->
                    <div
                        class="input-group mb-3 col-md-12 col-lg-4 p-0 d-flex date-container"
                        style="flex-wrap: nowrap; height: max-content"
                    >
                        <div class="row p-0 m-0 flex-grow-1">
                            <div class="col-md-12 p-0 m-0">
                                <DxDateBox
                                    class="flex-grow-1 flex-shrink-1"
                                    :label="$trans('strings.When')"
                                    label-mode="floating"
                                    v-model:value="startTime"
                                    type="date"
                                    display-format="yyyy.MM.dd"
                                    @value-changed="startTimeValueChanged"
                                />
                            </div>
                            <div
                                v-if="advancedMode"
                                style="width: 100%"
                                class="col-md-12 p-0 m-0 d-flex"
                            >
                                <div
                                    class="col-md-12 flex-shrink-1 p-0 m-0 pr-1"
                                >
                                    <DxNumberBox
                                        :label="$trans('strings.Date Minus')"
                                        label-mode="floating"
                                        v-model:value="daysMinus"
                                        :format="
                                            '#### ' + $trans('strings.days')
                                        "
                                        :show-spin-buttons="true"
                                    />
                                </div>
                                <div class="col-md-12 flex-shrink-1 p-0 m-0">
                                    <DxNumberBox
                                        :label="$trans('strings.Date Plus')"
                                        label-mode="floating"
                                        v-model:value="daysPlus"
                                        :format="
                                            '#### ' + $trans('strings.days')
                                        "
                                        :show-spin-buttons="true"
                                    />
                                </div>
                            </div>
                        </div>

                        <DxButton
                            class="pl-1 pr-1 ml-1 mt-2"
                            :hint="$trans('strings.Transports Search')"
                            :text="$trans('strings.Search')"
                            icon="search"
                            :visible="true"
                            :disabled="false"
                            :on-click="onSearchClick"
                        >
                        </DxButton>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="
                ready &&
                noResult &&
                (startPlace.placeId || endPlace.placeId || startTime)
            "
            class="mt-3"
            style="max-width: 1140px; margin: auto"
        >
            <div class="card p-0 shadow">
                <div class="card-body pt-2 pb-2 d-flex">
                    <DxButton
                        class="mx-auto no-result-add-wish"
                        style="height: 50px; font-weight: bold"
                        :text="
                            $trans(
                                'strings.No transport found -> Add new wish from search'
                            )
                        "
                        icon="plus"
                        :visible="true"
                        :disabled="false"
                        :on-click="onAddWishClick"
                    >
                    </DxButton>
                </div>
            </div>
        </div>
        <shipments-table
            :show-cart="showCart"
            @shipment-add-cart="shipmentAddCart"
            @total-count-changed="totalCountChanged"
            ref="shipmentsTable"
        ></shipments-table>
    </div>
</template>

<script>
import huMessages from "devextreme/localization/messages/hu.json";
import { locale, loadMessages } from "devextreme/localization";

import DxDateBox from "devextreme-vue/date-box";
import DxTextBox from "devextreme-vue/text-box";
import DxNumberBox from "devextreme-vue/number-box";
import DxButton from "devextreme-vue/button";

import ShipmentsTable from "@/common/components/ShipmentsTable.vue";
import LocationSelectBox from "@/common/components/LocationSelectBox.vue";

import KVUtils from "@/common/KVUtils";
import { nextTick } from "@vue/runtime-core";

export default {
    name: "ShipmentSearch",
    components: {
        DxDateBox,
        DxTextBox,
        DxNumberBox,
        DxButton,
        ShipmentsTable,
        LocationSelectBox,
    },
    props: { showCart: { default: true } },
    data() {
        return {
            ready: false,
            noResult: false,
            advancedMode: false,
            startRadius: 10,
            endRadius: 10,
            daysMinus: -1,
            daysPlus: 1,
            startPlace: { name: "", lat: 0, lng: 0, placeId: null },
            endPlace: { name: "", lat: 0, lng: 0, placeId: null },
            startTime: null,
        };
    },
    methods: {
        onSearchClick() {
            this.$refs.shipmentsTable.search({
                advancedMode: this.advancedMode,
                startRadius: this.startRadius,
                endRadius: this.endRadius,
                daysMinus: this.daysMinus,
                daysPlus: this.daysPlus,
                startPlace: this.startPlace,
                endPlace: this.endPlace,
                startTime: this.startTime,
            });
        },
        onTextChange(e) {
            console.log(e);
        },
        onWhereFromLocationChange(valid, location) {
            this.startPlace.name = location.name;
            if (valid) {
                this.startPlace.lat = location.lat;
                this.startPlace.lng = location.lng;
                this.startPlace.placeId = location.placeId;
            } else {
                this.startPlace.lat = null;
                this.startPlace.lng = null;
                this.placeId = null;
            }
        },
        onWhereToLocationChange(valid, location) {
            this.endPlace.name = location.name;
            if (valid) {
                this.endPlace.lat = location.lat;
                this.endPlace.lng = location.lng;
                this.endPlace.placeId = location.placeId;
            } else {
                this.endPlace.lat = null;
                this.endPlace.lng = null;
                this.endPlace.placeId = null;
            }
        },
        startTimeValueChanged(e) {
            this.startTime = e.value;
        },
        shipmentAddCart(e) {
            this.$emit("shipment-add-cart", e);
        },
        onAddWishClick() {
            const data = {
                startPlace: this.startPlace,
                endPlace: this.endPlace,
                startTime: this.startTime,
            };
            this.$emit("shipment-add-wish", data);
        },
        totalCountChanged(count) {
            console.log(count);
            this.noResult = count == 0;
        },
    },
    created() {},
    mounted() {
        //window.addEventListener("load", () => {
        //        nextTick(() => {
        const lang = document.getElementById("current-language").innerText;
        this.$lang.setLocale(lang);
        loadMessages(huMessages);
        locale(lang);
        this.ready = true;
        //        });
    },
};
</script>

<style lang="scss">
.shipment-search-container {
    .card {
        background-color: #e0e0e0a0;
        /* border: 2px solid blue; */
        border-radius: 10px !important;
        /* height: 60px; */
        font-size: 20px;
    }

    .card-title {
        margin: 8px 0px 2px -13px !important;
    }

    .date-container {
        height: 40px;
    }

    .card-right-top {
        position: absolute;
        right: 10px;
        top: 8px;
        font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana,
            sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: black !important;
    }
    .no-result-add-wish {
        .dx-icon {
            color: green;
            font-size: 28px;
            width: 30px;
            font-weight: bold;
        }
        .dx-button-text {
            font-size: 18px;
            color: floralwhite;
            text-shadow: 1px 1px 2px black, 0 0 4px blue, 0 0 5px darkblue;
        }
    }
}
</style>
