<template>
    <shipment-search
        :show-cart="showCart"
        @shipment-add-cart="shipmentAddCart"
        @shipment-add-wish="shipmentAddWish"
    />
</template>

<script>
import ShipmentSearch from "@/common/components/ShipmentSearch.vue";
import Cookies from "js-cookie";

export default {
    name: "StartPageSearch",
    components: {
        ShipmentSearch,
    },
    props: { showCart: { default: true } },
    data() {
        return {};
    },
    methods: {
        shipmentAddCart(e) {
            const id = e.row.data.id;
            Cookies.set("searchShipmentId", id, { expires: 1 });
            //Cookies.get("searchShipmentId");
            window.location.href = "/customer/search";
        },
        shipmentAddWish(data) {
            Cookies.set("customerAddWish", JSON.stringify(data), {
                expires: 1,
            });
            window.location.href = "/customer/addwish";
        },
    },
};
</script>

<style scoped></style>
