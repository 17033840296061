require('../common/bootstrap');

import { createApp } from 'vue';
import moment from 'moment';

require('lang.js');
import Vue3Langjs from 'vue3-langjs';
// import translations from '../vue-translations.js';
import Vue3ProgressBar from '@aacassandra/vue3-progressbar';

axios.get('/vue-translations.json').then((translations) => {

    const guestapp = createApp({})
        .use(Vue3Langjs, {
            messages: translations.data,
            fallback: 'en'
        })
        .use(Vue3ProgressBar, {
            color: 'green',
            failedColor: 'red',
            height: '5px',
            thickness: '10px',
            location: 'top'
        })
        .component('language-switcher', require('../common/components/LanguageSwitcher.vue').default)
        .component('start-page-search', require('../guestapp/components/StartPageSearch.vue').default)
        .mount('#guestapp');
});
