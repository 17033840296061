/*
Nyíregyháza, Hungary 47.94952174370563, 21.72443487348885
Hajdunánás, Hungary 47.843029996994346, 21.424462659706187
Debrecen 47.52922937593253, 21.623878869309973
Budapest, Hungary 47.49848921511964, 19.040291382756173
Győr, Hungary 47.693207336345075, 17.64889546212144

const geolist=[
    {lat:47.94952174370563,lng:21.72443487348885},
    {lat:47.49848921511964,lng:19.040291382756173},
    {lat:47.693207336345075,lng:17.64889546212144}
];
window.open(KVUtils.makeGoogleMapRouteLink(geolist), '_blank').focus();

*/

const ORDER_STATE = {
    WISH: 'wish',
    NEGOTIATING: 'negotiating',
    AGREED: 'agreed',
    ORDERED: 'ordered',
    FULFILLED: 'fulfilled'
}
import moment from "moment";
class KVUtils {

    static makeGoogleMapRouteLink(geolist) {
        // [{lat:1,lng:1}]
        let url = "";
        if (!geolist) return "";
        if (geolist.length == 1) {
            // 1 geo location
            url = "https://www.google.com/maps/@" + geolist[0].lat + "," + geolist[0].lng + ",18z";
        } else if (geolist.length == 2) {
            // 2 geo location - route
            // https: //google.com/maps?saddr=52.3668563,4.8890813&daddr=52.357516,4.902319&zoom=10
            url = "https://www.google.com/maps?saddr=" + geolist[0].lat + "," + geolist[0].lng + "&daddr=" +
                geolist[1].lat + "," + geolist[1].lng + "&zoom=10";
        } else {
            // n geo location - route with stops
            // https://maps.google.com/?saddr=52.3668563,4.8890813&daddr=52.357516,4.902319+to:52.365706932519416,%204.9087466759336404&zoom=10
            url = "https://www.google.com/maps?saddr=" + geolist[0].lat + "," + geolist[0].lng + "&daddr=" +
                geolist[1].lat + "," + geolist[1].lng;
            for (var i = 2; i < geolist.length; i++) {
                url += "+to:" + geolist[i].lat + "," + geolist[i].lng;
            }
            url += "&zoom=10";
        }
        return url;
    }


    // autocomplete.setComponentRestrictions({
    //     country: ["us", "pr", "vi", "gu", "mp"],
    //   });

    // static inputGoogleAutocompleteInit(input, cb) {
    //     const center = { lat: 50.064192, lng: -130.605469 };
    //     // Create a bounding box with sides ~10km away from the center point
    //     const defaultBounds = {
    //         north: center.lat + 0.1,
    //         south: center.lat - 0.1,
    //         east: center.lng + 0.1,
    //         west: center.lng - 0.1,
    //     };
    //     //        const input = document.getElementById("pac-input");
    //     const options = {
    //         bounds: defaultBounds,
    //         componentRestrictions: { country: "hu" },
    //         fields: ["address_components", "geometry", "icon", "name"],
    //         strictBounds: false,
    //         // types: ["establishment"],
    //         types: ["(cities)"],
    //     };
    //     const autocomplete = new google.maps.places.Autocomplete(input, options);
    //     autocomplete.addListener('place_changed', () => {
    //         var place = autocomplete.getPlace();
    //         cb(place.geometry.location.lat(),
    //             place.geometry.location.lng());
    //     });
    //     //     var place = autocomplete.getPlace();
    //     //     place.geometry.location.lat()
    //     //     place.geometry.location.lng()
    //     // });
    //     return autocomplete;
    // }

    static isEmpty(obj) {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }


    static isValid(n) {
        return typeof n != 'undefined' && n != null;
    }

    static ajaxResultHandler(response) {
        try {
            if (!response || !response.success) {
                console.error(response || response.message);
                alert(response.message || "Network Error!");
            }
            return response.success;
        } catch {
            return false;
        }
    }

    static ajaxErrorHandler(response) {
        if ("exception" in response && "message" in response) {
            alert(response.message);
        }
        console.error('ERRORHANDLER', response);
        KVUtils.serverSendLog('AJAXERROR', response, response);
    }

    static serverSendLog(logtype, text, description) {
        KVUtils.Post(null, '/common/clientlog', { logtype, text, description });
    }

    static clientError(err) {
        console.error(err);
        KVUtils.serverSendLog('CLIENTERROR', err, err);
    }

    static Post(_this, url, params) {
        if (_this) {
            _this.$Progress.start();
        }
        return axios
            .post(url, params)
            .then((res) => {
                if (KVUtils.ajaxResultHandler(res.data)) {
                    return res.data ? res.data.data : null;
                }
            })
            .catch((error) => {
                KVUtils.ajaxErrorHandler(error);
            })
            .finally(() => {
                if (_this) {
                    _this.$Progress.finish();
                }
            });
    }

    static PostWithResult(_this, url, params) {
        if (_this) {
            _this.$Progress.start();
        }
        return axios
            .post(url, params)
            .then((res) => {
                if (KVUtils.ajaxResultHandler(res.data)) {
                    return res.data;
                }
            })
            .catch((error) => {
                KVUtils.ajaxErrorHandler(error);
            })
            .finally(() => {
                if (_this) {
                    _this.$Progress.finish();
                }
            });
    }

    static initGeneralErrorHandler() {
        axios.interceptors.response.use(function(response) {
            return response;
        }, function(error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            if (error.response.status === 403 || error.response.status === 419) {
                window.location.href = "/login";
            }
            return Promise.reject(error);
        });
    }

    static decodeTranslatedField(s) {
        const lang = document.getElementById("current-language").innerText;
        if (!lang) {
            return s;
        }
        const k = '|' + lang.toLocaleUpperCase() + '|';
        const i = s.toLocaleUpperCase().indexOf(k);
        if (i == -1) {
            return s;
        }
        let s2 = s.substr(i + k.length);
        const j = s2.toLocaleUpperCase().indexOf('|');
        return j == -1 ? s2 : s2.substr(0, j);
    }

    // ODataStore -> fix fields to local time
    // static fixOdataLocalTime(rows, fields) {
    //     rows.forEach(row => {
    //         fields.forEach(field => {
    //             if (row[field]) {
    //                 var s = row[field];
    //                 row[field] = moment(row[field])
    //                     .local()
    //                     .toDate();
    //                 console.log('after', row['id'], field, s, ' -> ', row[field]);
    //             }
    //         });
    //     });
    // }

    static globalDateSerializationFormat = 'yyyy-MM-ddTHH:mm:ssZ';

    static oDataStoreGlobalConfig = {
        version: 4,
        beforeSend: function(e) {
            e.headers = {
                "odata-version": "4.0",
            };
        },
        deserializeDates: false,
        onLoaded: (data) => {
            // KVUtils.fixOdataLocalTime(data, [
            //     "start_time",
            //     "end_time",
            //     "email_verified_at",
            //     "updated_at",
            //     "created_at",
            // ]);
        },
        errorHandler: (error) => {
            console.log("ODATAERR:", error, error.message);
        },
    }

}

export default KVUtils;
export { ORDER_STATE };