<template>
    <div class="selectbox-container">
        <div class="icon-box">
            <i class="dx-icon-check valid-icon" v-if="valid"></i>
            <i class="dx-icon-clear invalid-icon" v-if="!valid"></i>
        </div>
        <DxSelectBox
            ref="selectbox"
            class="location-selectbox"
            :label="'&nbsp;&nbsp;&nbsp;&nbsp;' + label"
            v-model:value="value"
            label-mode="static"
            placeholder=""
            :search-enabled="true"
            min-search-lenght="1"
            value-expr="placeId"
            display-expr="name"
            :data-source="dataSource"
            :show-clear-button="true"
            @closed="onClosed"
            @value-changed="onValueChanged"
            @change="onChange"
            @input="onInput"
        >
        </DxSelectBox>
    </div>
</template>

<script>
import huMessages from "devextreme/localization/messages/hu.json";
import { locale, loadMessages } from "devextreme/localization";

import { DxSelectBox } from "devextreme-vue/select-box";
import DataSource from "devextreme/data/data_source";
import KVUtils from "@/common/KVUtils";
import { nextTick } from "@vue/runtime-core";

// input-group class kell a prentra!!!!
//  <div class="col-sm-9 input-group">
//    <LocationSelectBox

export default {
    name: "LocationSelectBox",
    components: {
        DxSelectBox,
    },
    props: ["label", "initValues"],
    data() {
        return {
            valid: false,
            value: "",
            updating: false,
            location: {
                name: "",
                placeId: "",
                lat: null,
                lng: null,
            },
            items: [],
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
            dataSource: new DataSource({
                load: (loadOptions) => {
                    if (
                        KVUtils.isEmpty(loadOptions.searchValue) ||
                        loadOptions.searchValue == null
                    ) {
                        return [];
                    }
                    this.$Progress.start();
                    const _this = this;
                    const data = axios
                        .post(
                            "/common/serach-location-autocomplete",
                            loadOptions,
                            {
                                headers: {
                                    "X-CSRF-TOKEN": this.csrf,
                                    "Content-Type": "application/json",
                                },
                            }
                        )
                        .then((res) => {
                            const data = KVUtils.isEmpty(res?.data)
                                ? []
                                : res?.data;
                            KVUtils.ajaxResultHandler(res?.data);
                            // console.log(
                            //     "autocomplete-response",
                            //     loadOptions,
                            //     data.data
                            // );
                            _this.items = data.data;
                            return data.data;
                        })
                        .catch((error) => {
                            throw "Network error";
                        })
                        .finally(() => {
                            this.$Progress.finish();
                        });

                    return data;
                },
                byKey: (key) => {},
            }),
        };
    },
    methods: {
        onValueChanged: function (e) {
            if (this.updating) return;
            //console.log("sb.valuechanged", e);
            this.location.placeId = e.value;
            this.location.name = this.items.find(
                (item) => item.placeId == e.value
            )?.name;
            //this.value = this.location.name;
            this.location.lat = null;
            this.location.lng = null;
            this.getLocationDetails();
        },

        onClosed: function () {
            if (
                !this.valid &&
                this.initValues &&
                this.initValues.place_id &&
                this.value == this.initValues.place_id
            ) {
                this.validate();
            }
        },

        clear: function () {
            this.clearValues();
        },

        onChange: function (e) {
            // console.log("onchange", e);
        },
        onInput: function (e) {
            // console.log("oninput", e);
            this.valid = false;
        },
        getLocationDetails: function () {
            this.$Progress.start();
            var _this = this;
            axios
                .post(
                    "/common/serach-location-details",
                    { placeId: this.location.placeId },
                    {
                        headers: {
                            "X-CSRF-TOKEN": this.csrf,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    const data = KVUtils.isEmpty(res?.data) ? [] : res?.data;
                    KVUtils.ajaxResultHandler(res?.data);
                    // console.log("response-details", data.data);
                    if (data.data.placeId == _this.location.placeId) {
                        _this.location.lat = data.data.lat;
                        _this.location.lng = data.data.lng;
                    }
                    _this.validate();
                })
                .catch((error) => {
                    throw "Network error";
                })
                .finally(() => {
                    _this.$Progress.finish();
                });
        },

        validate: function () {
            this.valid =
                KVUtils.isValid(this.location.placeId) &&
                KVUtils.isValid(this.location.lat) &&
                KVUtils.isValid(this.location.lng) &&
                KVUtils.isValid(this.location.name);
        },

        setValues: function (data) {
            const _this = this;
            if (
                data &&
                "geolat" in data &&
                "geolng" in data &&
                "geoname" in data &&
                "place_id" in data
            ) {
                this.updating = true;
                this.value = data.place_id;
                this.location.name = data.geoname;
                this.location.lat = data.geolat;
                this.location.lng = data.geolng;
                this.location.placeId = data.place_id;
                this.valid = true;
                const cont = this.$refs.selectbox.instance._$container[0];
                const input = cont.getElementsByClassName(
                    "dx-texteditor-input"
                )[0];
                nextTick(() => {
                    input.value = data.geoname;
                    _this.updating = false;
                });
            }
        },

        clearValues: function (data) {
            const _this = this;
            this.updating = true;
            this.value = "";
            this.location.name = "";
            this.location.lat = null;
            this.location.lng = null;
            this.location.placeId = "";
            this.valid = false;
            const cont = this.$refs.selectbox.instance._$container[0];
            const input = cont.getElementsByClassName("dx-texteditor-input")[0];
            nextTick(() => {
                input.value = "";
                _this.updating = false;
            });
        },

        init: function () {
            if (this.initValues) {
                this.setValues(this.initValues);
            }
        },
    },

    // setup(props, context) {
    //     console.log("Setup props:", props, context);
    //     // init();
    //     // emit("props-init");
    // },

    // beforeUpdate() {
    //     console.log("beforeupdate", this.initValues);
    // },

    mounted() {
        // console.log("mounted", this.initValues);
        this.init();
    },
    created() {
        // console.log("created", this.initValues);
        window.addEventListener("load", () => {
            const lang = document.getElementById("current-language").innerText;
            this.$lang.setLocale(lang);
            loadMessages(huMessages);
            locale(lang);
        });
    },
    watch: {
        initValues: function (newValue) {
            //console.log("initValues", newValue);
        },
        value: function (newValue) {
            //console.log("value changed", newValue);
        },
        valid: function () {
            // console.log(
            //     "valid changed",
            //     this.valid,
            //     JSON.stringify(this.location)
            // );
            if (this.updating) return;
            this.$emit("change-location", this.valid, this.location);
        },
    },
};
</script>

<style scoped>
.selectbox-container {
    margin-top: 0;
    position: inherit;
    font-size: 15px !important;
    width: 100%;
}
.location-selectbox {
    flex-grow: 1;
}

.icon-box {
    position: absolute;
    top: 1px !important;
    left: 7px;
    line-height: 15px;
    font-size: 15px;
    z-index: 1;
}

.valid-icon {
    font-size: 15px;
    color: green;
}
.invalid-icon {
    font-size: 15px;
    color: crimson;
}
</style>
